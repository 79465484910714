// fancyBox
$('[data-fancybox]').fancybox({
    // Options will go here
    buttons: [
        'slideShow',
        'fullScreen',
        'thumbs',
        'close'
    ]
});


// Referenz Map confirm
$('.button-confirm-referenz').click(function () {
    confirmedReferenz();
});

function confirmedReferenz(){
    $('#iframe-map').attr('src', 'https://maps.google.com/maps/ms?msa=0&msid=209327755835564656902.0004cbd9cfdc66a06d364&ie=UTF8&t=h&ll=47.717494,9.276024&spn=0.109546,0.419173&output=embed');
    $('.confirm-container').hide();
    $('.osm-map').show();
}


// Kontakt Map confirm
$('.button-confirm-kontakt').click(function () {
    confirmedKontakt();
});

function confirmedKontakt(){
    $('#iframe-map').attr('src', 'https://www.google.com/maps/d/embed?mid=133oAzbj_CShoOMtSpioOxBLl2hH8niEF');
    $('.confirm-container').hide();
    $('.osm-map').show();
}


// slick-slider
$('.referenzen-slick-slider').slick({
    arrows: true,
    dots: true,
    slidesToShow: 2,
    centerMode: false,
    // centerPadding: '80px',
    autoplay: true,
    rows: 2,
    prevArrow: $(".prev_arrow_referenzen"),
    nextArrow: $(".next_arrow_referenzen"),
    responsive: [
        {
            breakpoint: 700, // tablet breakpoint
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

$('.projekte-ansichten_slick-slider').slick({
    arrows: true,
    dots: true,
    slidesToShow: 2,
    centerMode: false,
    // centerPadding: '80px',
    autoplay: true,
    rows: 1,
    prevArrow: $(".prev_arrow_projekte-ansichten"),
    nextArrow: $(".next_arrow_projekte-ansichten"),
    responsive: [
        {
            breakpoint: 700, // tablet breakpoint
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

$('.projekte-grundrisse_slick-slider').slick({
    arrows: true,
    dots: true,
    slidesToShow: 2,
    centerMode: false,
    // centerPadding: '80px',
    autoplay: true,
    rows: 1,
    prevArrow: $(".prev_arrow_projekte-grundrisse"),
    nextArrow: $(".next_arrow_projekte-grundrisse"),
    responsive: [
        {
            breakpoint: 700, // tablet breakpoint
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});